import React from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { CountryContext } from '../context';
import { Country } from '../models';
import { GET_COUNTRIES } from '../graphql/queries';

const countriesPhoneData = [
  {
    code: 've',
    name: 'Venezuela',
    phone: '+58 424-1523627',
    wa: '+582122142131',
  },
  {
    code: 'py',
    name: 'Paraguay',
    phone: '(+595) 985103755',
    wa: '+595985103755',
  },
  {
    code: 'gt',
    name: 'Guatemala',
    phone: '(+502) 2335-3535',
    wa: '+50223353535',
  },
  {
    code: 'bo',
    name: 'Bolivia',
    phone: '(+591) 22112431',
    wa: '+59122112431',
  },
  {
    code: 'hn',
    name: 'Honduras',
    phone: '(+504) 22752174',
    wa: '+50422752174',
  },
  {
    code: 'sv',
    name: 'El Salvador',
    phone: '(+503) 2556-1123',
    wa: '+50325561123',
  },
  {
    code: 'mx',
    name: 'México',
    phone: '(+52) 81155 56 763',
    wa: '+528115556763',
  },
  {
    code: 'co',
    name: 'Colombia',
    phone: '(+57) 316 258 38 62',
    wa: '+573162583862',
  },
  {
    code: 'ni',
    name: 'Nicaragua',
    phone: '(+505) 22252175',
    wa: '+50522252175',
  },
];

export function useCountry() {
  const router = useRouter();
  const { category: selectedDocumentCategory } = router.query;

  const { currentCountry, setCurrentCountry } =
    React.useContext(CountryContext);

  const { data: countries, loading: loadingCountries } = useQuery<{
    countries: Country[];
  }>(GET_COUNTRIES, {
    variables: { filter: { active: true } },
    fetchPolicy: 'network-only',
  });

  const selectCountry = (country: Country) => {
    if (country._id === currentCountry._id) return;
    else {
      setCurrentCountry(country);
      if (selectedDocumentCategory) return router.push('/');
    }
  };

  const countryPhoneData = React.useMemo(
    () =>
      countriesPhoneData.find(
        (country) => country.name === currentCountry.name
      ),
    [currentCountry]
  );

  return {
    currentCountry,
    setCurrentCountry,
    selectCountry,
    countries,
    countryPhoneData,
    loadingCountries,
  };
}
